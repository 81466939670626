"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasRequiredFeatures = hasRequiredFeatures;
/**
 * This function exists because certain browser versions
 * do not support setting the `toString` property of a function,
 * which is required by redux toolkit.
 */
function canSetToString() {
  function test() {}
  try {
    test.toString = function () {};
    return true;
  } catch (e) {
    return false;
  }
}
function hasRequiredFeatures(window) {
  const featureDetectors = [typeof window.WeakMap === 'function', typeof window.requestAnimationFrame === 'function', typeof window.URLSearchParams === 'function', typeof Object.fromEntries === 'function', canSetToString()];
  return featureDetectors.every(featureDetector => featureDetector);
}